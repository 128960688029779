/* import __COLOCATED_TEMPLATE__ from './away-status-reasons-section.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import type AwayStatusReason from 'embercom/models/away-status-reason';
import type AppSettings from 'embercom/objects/inbox/app-settings';
import { put } from 'embercom/lib/ajax';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  awayStatusReasons: Array<AwayStatusReason>;
  appSettings: AppSettings;
}

interface Signature {
  Element: never;
  Args: Args;
}

export default class AwayStatusReasonsSection extends Component<Signature> {
  @service declare session: any;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @tracked awayReasonsEditorVisible = false;
  @tracked mandatory = this.args.appSettings.adminAwayReasonIsMandatory;

  get mandatorySwitchVisible() {
    return false;
  }

  get app() {
    return this.appService.app;
  }

  get canSetAwayStatusReasonsAsMandatory() {
    return this.session.workspace.canSetAwayStatusReasonsAsMandatory;
  }

  @action
  openAwayReasonsEditor() {
    this.awayReasonsEditorVisible = true;
  }

  @action
  closeAwayReasonsEditor() {
    this.awayReasonsEditorVisible = false;
  }

  @action
  async toggleMandatory() {
    this.mandatory = !this.mandatory;
    try {
      await put(`/ember/inbox/app_settings/update_admin_away_reason_is_mandatory`, {
        app_id: this.session.workspace.id,
        admin_away_reason_is_mandatory: this.mandatory,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.helpdesk.assignments.other.away-reasons.set-mandatory-success'),
      );
      await this.session.workspace.reloadAppSettings();
    } catch (error) {
      this.mandatory = !this.mandatory; // Revert the change
      this.notificationsService.notifyError(
        this.intl.t('new-settings.helpdesk.assignments.other.away-reasons.set-mandatory-failure'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::TabsComponents::AwayStatusReasonsSection': typeof AwayStatusReasonsSection;
  }
}
